import * as React from 'react';
import { Container, Box, useTheme } from '@mui/material';
import HistorySidebar from './components/HistorySidebar';
import { ChatProvider } from 'contexts/ChatContext';
import { MainLayout } from 'layouts';
import { useAuth } from 'contexts/AuthContext';

export default function Home() {

    const { userInfo } = useAuth();

    const theme = useTheme();


    return (
        <MainLayout>
            <ChatProvider currentUser={userInfo}>
                <Box sx={{ width: '100%' }}>
                    <HistorySidebar />
                </Box>
            </ChatProvider>
        </MainLayout>
    );

}
