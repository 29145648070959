import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import App from './App';
import theme from './theme';
import HyperDX from '@hyperdx/browser';
const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement!);

// TODO: Change prod mapping when DNS updates
if (window.location.hostname === 'darby.freetech.co') {
  HyperDX.init({
    apiKey: '010f76ed-e39b-4a1e-8a8f-909ee7b1f7d2',
    service: 'aa-darby',
    tracePropagationTargets: [/darby-backend.freetech.co\/api/i], // Set to link traces from frontend to backend requests
    // consoleCapture: true, // Capture console logs (default false)
    // advancedNetworkCapture: true, // Capture full HTTP request/response headers and bodies (default false)
  })
} 
// // Turn on for testing local
//   if (window.location.hostname === 'localhost') {
//   console.log('HyperDX initializing for local development')
//   HyperDX.init({
//     apiKey: '010f76ed-e39b-4a1e-8a8f-909ee7b1f7d2',
//     service: 'local-aa-darby',
//     tracePropagationTargets: [/stg-darby-backend.freetech.co\/api/i], // Set to link traces from frontend to backend requests
//     // tracePropagationTargets: [/0.0.0.0:3001\/api/i], // local backend
//     // consoleCapture: true, // Capture console logs (default false)
//     // advancedNetworkCapture: true, // Capture full HTTP request/response headers and bodies (default false)
//   })
//   console.log('HyperDX initialized for local development')
// }

// // Turn on for testing staging
// else if (window.location.hostname === 'stg-darby.freetech.co') {
//   HyperDX.init({
//     apiKey: '010f76ed-e39b-4a1e-8a8f-909ee7b1f7d2',
//     service: 'stg-aa-darby',
//     tracePropagationTargets: [/stg-darby-backend.freetech.co\/api/i], // Set to link traces from frontend to backend requests
//     // consoleCapture: true, // Capture console logs (default false)
//     // advancedNetworkCapture: true, // Capture full HTTP request/response headers and bodies (default false)
//   })
// }


root.render(
  <ThemeProvider theme={theme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />
    <App />
  </ThemeProvider>,
);
