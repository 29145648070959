import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useRef,
  ReactNode,
} from "react";
import { sendChatMessage } from "core/api/api";


import { getChatSessionsFromFirestore } from "core/api/getChatSessionsFromFirestore";
import { getChatHistoryFromFirestore } from "core/api/getChatHistoryFromFirestore";
import { updateCurrentChatInFirestore } from "core/api/updateCurrentChatInFirestore";
import { getFirestore, doc, collection, addDoc, getDoc } from "firebase/firestore";
import { useAuth } from "./AuthContext";
import { getCurrentUserId } from "core/api/api";


const db = getFirestore();


interface ChatContextType {
  inputText: string;
  setInputText: (input: string) => void;
  history: HistoryEntry[];
  setHistory: (history: HistoryEntry[]) => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  handleSubmit: () => Promise<void>;
  bottomRef: React.RefObject<HTMLDivElement>;
  selectedFilter: string | null;
  setSelectedFilter: (filter: string) => void;
  chatSessionList: HistorySession[];
  switchChat: (sessionId: string) => void;
  currentSessionId: string;
  initializeNewSession: () => void;
  loadChatHistoryList: () => void;
  createNewChat: () => void;
  firstChatLoading: { isLoading: boolean, index: number };
}

const ChatContext = createContext<ChatContextType | null>(null);

export const useChat = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error("useChat must be used within an ChatContext");
  }
  return context;
};

interface ChatProviderProps {
  children: ReactNode;
  currentUser: UserInfoType | null;
}

export const ChatProvider = ({ children, currentUser }: ChatProviderProps) => {

  const userId = getCurrentUserId() || "";

  const [selectedFilter, setSelectedFilter] = useState("");
  const [inputText, setInputText] = useState("");

  const [chatSessionList, setChatSessionList] = useState<HistorySession[]>([]);

  const [history, setHistory] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const bottomRef = useRef<HTMLDivElement>(null);

  const [firstChatLoading, setFirstChatLoading] = useState({
    isLoading: false,
    index: -1
  });

  const [currentSessionId, setCurrentSessionId] = useState<string>("");


  const loadChatHistoryList = async () => {
    if (currentUser) {

      const chatList = await getChatSessionsFromFirestore(userId);
      const sortedChatList = chatList.sort((a, b) => parseFloat(b.date_created) - parseFloat(a.date_created));

      setChatSessionList(sortedChatList);

      if (sortedChatList.length > 0) {
        const userDocRef = doc(db, "users", userId);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const lastChatSessionId = userDoc.data().currentChat;
          if (lastChatSessionId) {
            setCurrentSessionId(lastChatSessionId);
            const messages = await getChatHistoryFromFirestore(userId, lastChatSessionId);
            setHistory(messages);
          } else {
            setCurrentSessionId(sortedChatList[0].id); // Fallback to the first chat if no last chat is found
          }
        } else {
          setCurrentSessionId(sortedChatList[0].id); // Fallback to the first chat if the user document doesn't exist
        }
      }
    }
  };



  const createNewChat = async () => {
    const sessionId = await initializeNewSession();
    setCurrentSessionId(sessionId);
    setHistory([]); // Clear the chat history
  }


  const switchChat = async (sessionId: string) => {
    setCurrentSessionId(sessionId);
    await updateCurrentChatInFirestore(userId, sessionId);
    console.log("Switching to chat:", sessionId);
    const messages = await getChatHistoryFromFirestore(userId, sessionId);
    // console.log("Messages for:", sessionId, messages);
    setHistory(messages);
  };

  const handleSubmit = async () => {
    if (!inputText.trim()) return;
    setIsLoading(true);
    scrollToBottom();
    try {

      console.log("currentSessionId in submit:", currentSessionId);

      let data: any;

      const userDocRef = doc(db, "users", userId);
      const userDoc = await getDoc(userDocRef);
      let latestInstructions = "";

      // Grab chat_custom_instructions value
      if (userDoc.exists()) {
        latestInstructions = userDoc.data().chat_custom_instructions || "";
        // console.log('latestInstructions', latestInstructions)
      }
      const inputTextWithInstructions = `${inputText} ${latestInstructions}`;

      //  TODO: Refactor this to use the initializeNewSession function

      if (!currentSessionId) {
        setFirstChatLoading(prevState => ({ ...prevState, isLoading: true }));
        const sessionId = await initializeNewSession();
        setCurrentSessionId(sessionId);
        data = await sendChatMessage(inputTextWithInstructions, sessionId, selectedFilter);
        setFirstChatLoading({ isLoading: false, index: -1 });
      } else {
        data = await sendChatMessage(inputTextWithInstructions, currentSessionId, selectedFilter);
      }

      const parsed_data = JSON.parse(data);
      const newHistory = [...history, { user: inputTextWithInstructions, darby: parsed_data }];
      setHistory(newHistory);
      loadChatHistoryList();
      scrollToBottom();
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
      setInputText("");
    }
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  const initializeNewSession = async () => {
    const chatHistoryRef = collection(db, `users/${userId}/chat_history`);
    const docRef = await addDoc(chatHistoryRef, {
      createdAt: new Date().getTime().toString(),//new Date(),
      messages: [],
      name: "New Chat Session"
    });

    console.log("New session created with ID:", docRef.id);

    const newSession = {
      id: docRef.id,
      name: docRef.id,
      date_created: new Date().getTime().toString()//.toISOString()
    };

    setChatSessionList((prevList) => {
      const updatedList = [...prevList, newSession];
      const newSessionIndex = updatedList.length - 1;
      setFirstChatLoading(prevState => ({ ...prevState, index: newSessionIndex }));
      return updatedList;
    });

    setCurrentSessionId(docRef.id);
    await updateCurrentChatInFirestore(userId, docRef.id);
    setHistory([]); // Clear the chat history
    console.log("New session created with ID:", docRef.id);
    return docRef.id;
  };



  useEffect(() => {
    const loadAndRefreshChatHistoryList = async () => {
      await loadChatHistoryList();
      if (currentUser && currentSessionId) {
        console.log('current session id is', currentSessionId);
        const messages = await getChatHistoryFromFirestore(userId, currentSessionId);
        setHistory(messages);
        scrollToBottom();
      }
    };

    if (!firstChatLoading.isLoading)
      loadAndRefreshChatHistoryList();

  }, [currentUser, currentSessionId, firstChatLoading.isLoading]);

  // useLayoutEffect(() => {
  //   scrollToBottom();
  // }, [history]);

  const value = {
    inputText,
    setInputText,
    history,
    setHistory,
    isLoading,
    setIsLoading,
    handleSubmit,
    bottomRef,
    selectedFilter,
    setSelectedFilter,
    chatSessionList,
    switchChat,
    currentSessionId,
    initializeNewSession,
    loadChatHistoryList,
    createNewChat,
    firstChatLoading
  };

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};