import React from 'react';

import Navbar from './components/NavBar';
import Footer from 'components/Footer';
import { Box, Container } from '@mui/system';

interface Props {
    children: React.ReactNode;
}



const Main = ({
    children,
}: Props): JSX.Element => {

    return (



        <Box sx={{ position: 'relative', width: '100%', }}>
            <Navbar/>
            <Box sx={{ width: '100%', marginTop: 0}}>
                {children}
            </Box>
            <Footer />
        </Box>
    );
}
export default Main;