import React, { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { fetchPDFList } from 'core/api/fetchPDFList';

interface PDFItem {
  name: string;
  url: string;
  last_updated: string;
  author: string;
  num_pages: string;
  pdf_url: string;
  title: string;
  type: string;
  functional_area: string;
  source_url: string;
  filename: string;
  publisher_category: string;
  publisher: string;
  company: string;
  last_modified: string;
}

interface KnowledgeBaseProps {
  open: boolean;
  onClose: () => void;
}

const KnowledgeBase: React.FC<KnowledgeBaseProps> = ({ open, onClose }) => {
  const [pdfItems, setPdfItems] = React.useState<PDFItem[]>([]);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    if (open) {
      const fetchData = async () => {
        setLoading(true);
        const items = await fetchPDFList();
        setPdfItems(items);
        setLoading(false);
      };
      fetchData();
    }
  }, [open]);

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose}>
      <DialogTitle>
        <Typography variant="h4">Knowledge Base</Typography>
        <Typography sx={{ fontSize: '0.875rem' }} color="textSecondary">
          Doc Count: {pdfItems.length}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <Typography>Loading...</Typography>
          </Box>
        ) : (
          <Grid container spacing={2}>
            {pdfItems.map((item, index) => (
              <Grid item xs={12} md={6} lg={4} key={index}>
                <Paper
                  elevation={5}
                  sx={{
                    padding: 2,
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box sx={{ marginBottom: 2 }}>
                    <Typography variant="h6" gutterBottom noWrap>
                      {item.title || item.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Author: {item.author}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                    Type: {item.type}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Pages: {item.num_pages}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Published: {item.last_updated}
                    </Typography>
                    {/* <Typography variant="body2" color="textSecondary">
                      Modified: {item.last_modified}
                    </Typography> */}
                    <Typography variant="body2" color="textSecondary">
                      Functional Area: {item.functional_area}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Publisher: {item.publisher} | Category: {item.publisher_category}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body2">
                      <Link
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{ color: 'blue', '&:hover': { color: 'red' } }}
                      >
                        View Document
                      </Link>
                    </Typography>
                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" sx={{ color: 'blue', '&:hover': { color: 'red' } }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default KnowledgeBase;
