import React, { useEffect, useState } from 'react';
import MainLayout from 'layouts/MainLayout';
import { Box, Container, Avatar, Typography, Paper, Grid, Button, Divider } from '@mui/material';
import { deepPurple } from '@mui/material/colors';
import { useAuth } from 'contexts/AuthContext';
import ProfileInfoDialog from './components/ProfileInfoDialog';
import { ManageSubscription } from './components/ManageSubscription';
import { getCurrentUserId } from 'core/api/api';
import { getStripeSubStatusFromFirestore } from 'core/api/getStripeSubStatusFromFirestore';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from 'core/config/firebase';
import CheckIcon from '@mui/icons-material/Check';

const Profile = (): JSX.Element => {
    const [profileDialogVisible, setProfileDialog] = useState(false);

    const uid = getCurrentUserId();
    const [subscriptionData, setSubscriptionData] = useState<any | null>(null);
    

    const { userInfo } = useAuth();
    const userData = {
        name: userInfo?.first_name + " " + userInfo?.last_name,
        first_name: userInfo?.first_name,
        last_name: userInfo?.last_name,
        email: userInfo?.email,
        company: userInfo?.company_name,
        user_type: userInfo?.user_type
    };

    useEffect(() => {
        if (uid) {
          getStripeSubStatusFromFirestore(uid)
            .then(async ({status, data}) => {
              setSubscriptionData(data);
            //   console.log("Subscription-based user type:", status);
            })
        }
      }, []);

    const onDialogClose = () => {
        setProfileDialog(false);
    }

    const openDialog = () => {
        setProfileDialog(true);
    }

    return (
        <MainLayout>
            <Container component="main"  sx={{ mb: 25, maxWidth: {m: "lg"} }}>
                <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column' }}>
                    {/* <ProfileInfoDialog isOpen={profileDialogVisible} onClose={onDialogClose} companyInfo={null} /> */}
                    
                    <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}, width: '100%', height: '70vh', alignItems: 'center' }}>
                        <Box sx={{display:'flex', flex: 1, flexDirection: 'column', alignSelf:'center', justifyContent: 'center', alignItems: 'center'}}>
                            <Avatar sx={{ m: 1, bgcolor: deepPurple[500], width: 100, height: 100 }}>
                                {userData.first_name?.charAt(0)}{userData.last_name?.charAt(0)} 
                            </Avatar>
                            <Typography variant="h6">
                                {userData.name}
                            </Typography>
                        </Box>
                        <ManageSubscription userData={userData} subscriptionData={subscriptionData}/>
                    </Box>
                </Box>
                {/* <Box sx={{ }}>
                <ManageSubscription userData={userData} subscriptionData={subscriptionData}/>
                </Box> */}
                {/* <Grid container spacing={2} sx={{ marginTop: 2 }}>
                    <Grid item>
                        <Button variant="contained" color="primary">
                            Edit Profile
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="outlined">
                            Change Password
                        </Button>
                    </Grid>
                </Grid> */}
            </Container>
        </MainLayout>
    );
}

export default Profile;
