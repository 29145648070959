import {
    collection,
    getDocs,
    getFirestore,
} from 'firebase/firestore';

const db = getFirestore();

export const getChatSessionsFromFirestore = async (userId: string): Promise<HistorySession[]> => {
    if (!userId) {
        throw Error("User ID is not defined");
    }
    try {
        const docsSnapshot = await getDocs(collection(db, "users", userId, "chat_history"));

        const chatList: HistorySession[] = [];
        docsSnapshot.forEach((doc) => {
            const data = doc.data()
            if (!data){
                return
            }
            // try {
            //     chatList.push({
            //         id: data.id,
            //         name: data.messages[1].data.chat_title,
            //         date_created: (data.messages[data.messages.length - 1].data.additional_kwargs.timestamp * 1000).toString()
                    
            //     });
            // } catch {
            //     chatList.push({
            //         id: data.id,
            //         name: '...',
            //         date_created: "date" + data.id,
            //     });
            // }
            try {
                // Access the timestamp from the last message
                const lastMessage = data.messages[data.messages.length - 1];
                const timestamp = lastMessage.data.additional_kwargs.timestamp;
            
                // Convert timestamp to milliseconds and format as string
                const dateCreated = (timestamp * 1000).toString();
            
                // Log the date_created value to the console
                console.log('date_created:', dateCreated);
                const name1 = data.messages[1].data.chat_title
            
                // Push the formatted data to chatList
                chatList.push({
                    id: data.id,
                    name: name1,//data.messages[1].data.chat_title,
                    date_created: dateCreated
                });
            } catch (error) {
                // Log error details for debugging
                console.error('Error processing chat data:', error);
            
                // Push default values to chatList in case of an error
                chatList.push({
                    id: data.id,
                    name: '...',
                    date_created: "date" + data.id,
                });
            }

        });

        return chatList;
    } catch (error) {
        console.error("Error fetching document names:", error);
        return [];
    }
};
