import { get } from "http";
import { getHeaders } from "core/api/api";
import { httpCodes } from "core/constants/httpCodes";
import { deleteUserFirestoreProfile } from "core/api/firestore";

export const deleteFirebaseUser = async (userId: any) => {
  // This will delete a user along with all objects associated with that user via UID via the extension

  // const functionUrl = "http://127.0.0.1:5001/otw-production-91ffb/us-central1/deleteFirebaseUser"
  const functionUrl =
    "https://us-central1-otw-production-91ffb.cloudfunctions.net/deleteFirebaseUser";


  const res = await fetch(functionUrl, {
    method: "POST",
    headers: await getHeaders(),
    body: JSON.stringify({ userId }),
  });
  
    await deleteUserFirestoreProfile(userId);
  
  
    return res.json();
  

};

export const createFirebaseUser = async (userData: any) => {
    // const functionUrl = "http://127.0.0.1:5001/otw-production-91ffb/us-central1/createFirebaseUser"
    const functionUrl =
  'https://us-central1-otw-production-91ffb.cloudfunctions.net/createFirebaseUser'
    const res = await fetch(functionUrl, {
      method: "POST",
      headers: await getHeaders(),
      body: JSON.stringify(userData),
    });
    if (res.status === 200) {
      return "Created";
    } else {
      const status = res.status
      const message = httpCodes[status] || "An error occurred, please contact Chase."
      return message;
    }
  };

  

export const resendVerificationEmail = async (email: string) => {
//   const functionUrl =
//     "http://127.0.0.1:5001/otw-production-91ffb/us-central1/resendVerificationEmail";
//   
  const functionUrl = "https://us-central1-freetech-production.cloudfunctions.net/deleteFirebaseUser"

  const res = await fetch(functionUrl, {
    method: "POST",
    headers: await getHeaders(),
    body: JSON.stringify({ email }),
  });
  if (res.status === 200) {
    return true;
  } else {
    return false;
  }
};

export const sendRequestedUserEmail = async (email: string, first_name: string, last_name: string) => {
    const functionUrl = "http://127.0.0.1:5001/aa-fb-prod/us-central1/sendEmail"
    // const functionUrl ='https://us-central1-aa-fb-prod.cloudfunctions.net/sendEmail'

    const res = await fetch(functionUrl, {
      method: "POST",
      headers: await getHeaders(),
      body: JSON.stringify({email, first_name, last_name}),
    });
    if (res.status === 200) {
      return true;
    } else {
      return false;
    }
  };
  
  // ^^^  ALL FUNCTIONS ABOVE UNUSED, BUT DEPENDANT BY AUTHCONTEX ^^^ 
  export const checkSubscriptionStatus = async (uid: string): Promise<{ status: string | null, message: string }> => {
    // const functionUrl = "http://127.0.0.1:5001/aa-fb-prod/us-central1/checkSubscriptionStatus"; //local
    const functionUrl = 'https://us-central1-aa-fb-prod.cloudfunctions.net/checkSubscriptionStatus' // fb

    try {
        const res = await fetch(functionUrl, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}` 
            },
            body: JSON.stringify({ uid })
        });

        if (res.status === 200) {
            const data = await res.json();
            return { status: data.status, message: "Subscription status fetched successfully" };
        } else if (res.status === 404) {
            return { status: null, message: "No subscription found" };
        } else {
            return { status: null, message: "Failed to fetch subscription status" };
        }
    } catch (error) {
        console.error("Error fetching subscription status:", error);
        return { status: null, message: "Error fetching subscription status" };
    }
};

export const updateUserType = async (uid: string, userType: string): Promise<void> => {
  // const functionUrl = "http://127.0.0.1:5001/aa-fb-prod/us-central1/updateUserType"; // local
  const functionUrl = 'https://us-central1-aa-fb-prod.cloudfunctions.net/updateUserType' // fb

  try {
      const res = await fetch(functionUrl, {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          body: JSON.stringify({ uid, userType })
      });

      if (res.status === 200) {
          console.log("User type updated successfully");
      } else {
          console.error("Failed to update user type");
      }
  } catch (error) {
      console.error("Error updating user type:", error);
  }
};
