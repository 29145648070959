import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
  Tooltip,
} from "@mui/material";
import { db } from "core/config/firebase";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { getCurrentUserId } from "core/api/api";
import { useAuth } from "contexts/AuthContext";
import { Edit } from "@mui/icons-material";
import CampaignIcon from '@mui/icons-material/Campaign';

type CustomInstructionProps = {
  dialogOpen: boolean;
  setDialogOpen: (dialogOpen: boolean) => void;
  instructionText: string;
  setInstructionText: (text: string) => void;
  setInstructions: (newInstructions: string) => void;
}

const CustomInstructions = ({ dialogOpen, setDialogOpen, instructionText, setInstructionText, setInstructions}: CustomInstructionProps): JSX.Element => {

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleInstructionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInstructionText(event.target.value);
  };


  const handleInstructionSubmit = async () => {
    try {
      await setInstructions(instructionText);
      console.log("custom instructions successfully updated on firestore");
      handleDialogClose();
    } catch (error) {
      console.error("Error updating instructions:", error);
    }
  };

  return (
    // <>
    //   <Tooltip title="Custom Instructions" placement="right">
    //     <CampaignIcon onClick={handleDialogOpen}>
    //       <Edit />
    //     </CampaignIcon>
    //   </Tooltip>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Enter Custom Instructions</DialogTitle>
        <DialogContent>
          <DialogContentText>
            These Instructions Will Persist Across Conversations
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Instructions"
            type="text"
            fullWidth
            variant="outlined"
            multiline
            rows={7}
            value={instructionText} // grabs value from firestore to autofill box
            onChange={handleInstructionChange}
            sx={{ height: "300px" }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleInstructionSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    // </>
  );
};

export default CustomInstructions;