import * as React from 'react';
import { AppRoutes } from './routes/AppRoutes';
import { AuthProvider } from './contexts/AuthContext';
import { getUserInfo } from 'core/api/users';
import LogRocket from 'logrocket';

LogRocket.init('bgoftk/darby');


export default function App() {

  return (
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
  );
}
