import React, { useState } from 'react';
import {
    FormControl, 
    FormControlLabel, 
    FormLabel, 
    Radio, 
    RadioGroup 
} from '@mui/material';
import { useChat } from 'contexts/ChatContext';

export const Filter = () => {
    const { selectedFilter, setSelectedFilter } = useChat();

    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedFilter(event.target.value);
    };

    return (
        <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">Document Type</FormLabel>
            <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={selectedFilter} 
                onChange={handleFilterChange} 
            >
                <FormControlLabel value="policy" control={<Radio />} label="Policy" />
                <FormControlLabel value="guidebook" control={<Radio />} label="Guidebook" />
                <FormControlLabel value="" control={<Radio />} label="Both" />
            </RadioGroup>
        </FormControl>
    );

}

