import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MainLayout } from "layouts";
import { createCheckoutSession } from "@invertase/firestore-stripe-payments";
import {
  CircularProgress,
  Box,
  Typography,
} from "@mui/material";
import { useAuth } from "contexts/AuthContext";
import { getCurrentUserId } from "core/api/api";
import ClientView from "./ClientView";
import CustomerView from "./CustomerView";
import { getStripeSubStatusFromFirestore } from "core/api/getStripeSubStatusFromFirestore";
import { db } from "core/config/firebase";
import { doc, updateDoc } from "firebase/firestore";

type ManageSubscriptionProps = {
  userData: any;
  subscriptionData: any;
}

const ManageSubscription: React.FC<ManageSubscriptionProps> = ({userData, subscriptionData}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [subscriptionUserType, setSubscriptionUserType] = useState<string | null>(null);
  const { payments, userInfo } = useAuth();
  const userType = userInfo?.user_type;
  const uid = getCurrentUserId();
  const navigate = useNavigate();

  // Log the user type whenever it changes
  useEffect(() => {
    console.log("User type:", userType);
    if (uid) {
      getStripeSubStatusFromFirestore(uid)
        .then(async ({status, data}) => {
          setSubscriptionUserType(status);
          console.log("Subscription-based user type:", status);

          try {
            const userDocRef = doc(db, "users", uid);
            await updateDoc(userDocRef, {
              user_type: status, // ensure sub status matches user type
            });
          } catch (error) {
            console.error("Error updating data:", error);
          }
        })
        .catch((error) => {
          console.error("Error fetching subscription status:", error);
        });
    }
  }, [userType, uid]);

  // Check if the user is returning from Stripe and refresh the page
  useEffect(() => {
    const stripeRedirect = localStorage.getItem("stripeRedirect");
    if (stripeRedirect) {
      localStorage.removeItem("stripeRedirect");
      setTimeout(() => {
        window.location.reload();
      }, 2000); // Wait for 2 seconds before refreshing. We need to wait for stripe webhook plus firestore writes
    }
  }, []);

  // Function to initiate the Stripe checkout process
  const doStripeStuff = async () => {
    if (!payments) {
      return;
    }

    if (!uid) {
      return;
    }

    setIsLoading(true);

    try {
      console.log("Firing Stripe checkout");
      const session = await createCheckoutSession(payments, {
        // if using test mode, stripe extension needs to be reconfigured
        price: "price_1PtsVAG1pOntwkUF8hAr5x64", // prod price ID
        allow_promotion_codes: true,
        success_url: `${window.location.origin}/profile`,
        cancel_url: `${window.location.origin}/profile`,
      });

      // Set a flag in local storage before redirecting
      localStorage.setItem("stripeRedirect", "true");

      // Redirect to Stripe checkout
      window.location.assign(session.url);
    } catch (error) {
      console.error("Error creating Stripe session:", error);
      setIsLoading(false);
    }
  };

  // Function to navigate to chat
  const handleGoToChat = () => {
    navigate("/");
  };

  return (
      <Box sx={{display: 'flex'}}>
      {isLoading && userType === "customer" ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
          <CircularProgress />
        </Box>
      ) : (
        <>
          {userType === "customer" ? (
            <CustomerView
              isLoading={isLoading}
              handleGoToChat={handleGoToChat}
              alterSubscriptionUrl="https://billing.stripe.com/p/login/00gbJy7jT18E4E03cc" // prod customer portal url
              userData={userData}
              subscriptionData={subscriptionData}
            />
          ) : (
            <ClientView
              isLoading={isLoading}
              doStripeStuff={doStripeStuff}
              userData={userData}
              subscriptionData={subscriptionData}
            />
          )}
        </>
      )}
  </Box>
  );
};

export default ManageSubscription;
